<template>
  <div class="row">
    <div class="modal fade" id="modal-form-turno-cambio-ttrans">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cecos y afes</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-4">
                <label>Tipo presupuesto</label>
                <select
                  class="form-control"
                  :class="
                    $v.form.tipo_presupuesto.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  v-model="form.tipo_presupuesto"
                  @change="getCecosAfes()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo in listasForms.tipos_presupuesto"
                    :key="tipo.id"
                    :value="tipo.numeracion"
                  >
                    {{ tipo.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Codigo Contable</label>
                <v-select
                  v-model="cecoAfe"
                  class="form-control p-0"
                  :class="[
                    $v.form.ceco_afe_id.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-if="form.tipo_presupuesto"
                  placeholder="CeCo / AFE"
                  @input="selectCecoAfe()"
                  label="codigo_contable"
                  :options="listasForms.cecos_afes"
                ></v-select>
              </div>

              <div class="form-group col-md-3">
                <label>Porcentaje</label>
                <input
                  type="number"
                  class="form-control"
                  :class="
                    $v.form.porcentaje.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  v-model="form.porcentaje"
                />
              </div>
              <div class="col-md-1 pt-4">
                <button
                  class="btn btn-primary mt-1"
                  @click="addCecoAfe()"
                  v-if="indexEdit == null && !$v.form.$invalid"
                >
                  <i class="fas fa-plus"></i>
                </button>

                <button
                  class="btn btn-success mt-1"
                  @click="saveEdicCecoAfe()"
                  v-if="indexEdit !== null && !$v.form.$invalid"
                >
                  <i class="fas fa-check"></i>
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4 :class="total < 100 ? 'text-danger' : 'text-success'">
                  Total: {{ total }}%
                </h4>
                <table class="table table-sm table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Tipo Presupuesto</th>
                      <th>Codigo contable</th>
                      <th>Porcentaje</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ceco, index) in cecos_afes" :key="index">
                      <td>
                        {{
                          listasForms.tipos_presupuesto.find(
                            (t) => t.numeracion == ceco.tipo_presupuesto
                          ).descripcion
                        }}
                      </td>
                      <td>{{ ceco.cecoAfe.codigo_contable }}</td>
                      <td>{{ ceco.porcentaje }}</td>
                      <td>
                        <div class="btn-group">
                          <button
                            class="btn btn-success"
                            @click="editCecoAfe(ceco, index)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn btn-danger"
                            @click="deletCecoAfe(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="$store.getters.can('tep.turnos.edit') && total == 100"
            >
              Cambiar operacion
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
export default {
  name: "TepTurnoCACambioTTrans",
  components: {
    vSelect,
  },
  data() {
    return {
      cargando: false,
      data_turno: {},
      turno_id: null,
      cecoAfe: null,
      listasForms: {
        tipos_presupuesto: [],
        cecos_afes: [],
      },
      form: {
        tipo_presupuesto: null,
        ceco_afe_id: null,
        porcentaje: null,
      },
      cecos_afes: [],
      indexEdit: null,
      total: 0,
    };
  },
  validations() {
    return {
      form: {
        tipo_presupuesto: {
          required,
        },
        ceco_afe_id: {
          required,
        },
        porcentaje: {
          required,
          numeric,
          minLength: minLength(0),
          maxLength: maxLength(100),
        },
      },
    };
  },

  methods: {
    async llenar_modal(item) {
      this.data_turno = item;
      await this.getTiposPresupuesto();
      this.form = {
        ceco_afe_id: null,
        tipo_presupuesto: null,
        porcentaje: null,
      };

      this.indexEdit = null;
      this.porcentajeTotal();
    },

    async getTiposPresupuesto() {
      await axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipos_presupuesto = response.data;
      });
    },

    async getCecosAfes(tipoP = null) {
      tipoP = this.form.tipo_presupuesto;
      this.listasForms.cecos_afes = [];
      this.cecoAfe = null;
      this.form.ceco_afe_id = null;
      this.$parent.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/wsObjetosActivos/lista", {
          params: {
            tipo_presupuesto: tipoP,
          },
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.listasForms.cecos_afes = response.data;
        });
    },

    async selectCecoAfe() {
      this.form.ceco_afe_id = null;
      if (this.cecoAfe) {
        this.form.ceco_afe_id = this.cecoAfe.id;
      }
    },

    async addCecoAfe() {
      let data = {
        ceco_afe_id: this.form.ceco_afe_id,
        cecoAfe: this.cecoAfe,
        tipo_presupuesto: this.form.tipo_presupuesto,
        porcentaje: this.form.porcentaje,
      };

      this.cecos_afes.push(data);

      this.form = {
        ceco_afe_id: null,
        tipo_presupuesto: null,
        porcentaje: null,
      };

      this.indexEdit = null;
      this.porcentajeTotal();
    },

    async editCecoAfe(item, index) {
      await this.getCecosAfes(item.tipo_presupuesto);
      this.form = item;
      this.cecoAfe = item.cecoAfe;
      this.indexEdit = index;
    },

    async saveEdicCecoAfe() {
      let data = {
        ceco_afe_id: this.form.ceco_afe_id,
        cecoAfe: this.cecoAfe,
        tipo_presupuesto: this.form.tipo_presupuesto,
        porcentaje: this.form.porcentaje,
      };
      this.cecos_afes[this.indexEdit] = data;

      this.form = {
        ceco_afe_id: null,
        tipo_presupuesto: null,
        porcentaje: null,
      };

      this.indexEdit = null;
      this.porcentajeTotal();
    },

    async deletCecoAfe(index) {
      this.cecos_afes.splice(index, 1);
      this.porcentajeTotal();
    },

    async porcentajeTotal() {
      let total = 0;
      this.cecos_afes.forEach((c) => {
        total += parseInt(c.porcentaje);
      });
      this.total = total;
    },

    async save() {
      await this.$parent.cambiarTipoTransporte(
        this.data_turno,
        this.cecos_afes
      );
    },
  },
};
</script>
